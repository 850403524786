.App {
  text-align: center;
}

.steps {
  margin-top: 24px;
  margin-right: 10%;
  margin-left: 10%;
  /* border: 1px dashed #e9e9e9; */
}

.steps-content {
  margin-top: 24px;
  margin-right: 10%;
  margin-left: 10%;
  text-align: center;
  /* border: 1px dashed #e9e9e9; */
}

.steps-content-mobile {
  margin-top: 24px;
  margin-right: 5%;
  margin-left: 5%;
  text-align: center;
  /* border: 1px dashed #e9e9e9; */
}

.steps-action {
  margin-top: 24px;
}

.toogle {
  /* margin-top: 48px; */
  margin-right: 10%;
  margin-left: 10%;
  display: flex;
  justify-content: 'left';
}

.scan-button {
  text-align: center;
}

.customize-titles {
  text-align: center;
}

.gutter-row {
  border: 1;
  border-color: red;
}

.customize-input {
  margin-right: 30%;
  margin-left: 30%;
  display: flex;
  flex-direction: row;
}

.dynamic-delete-button {
  position: relative;
  top: 6px;
  margin: 0 16px;
  color: #999;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.vault-cards {
  margin-bottom: 64px;
}

.back-button {
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
  /* border: 1px dashed #e9e9e9; */
}

.scan-nft {
  margin-top: 40px;
}
.div-subtitle {
  margin-top: 8px;
  margin-left: 5px;
}
.steps-icon-size {
  font-size: 30px;
  margin-right: 10;
  margin-bottom: 20;
}
.create-icons-container {
  /* margin-top: 24px; */
  margin-right: 5%;
  margin-left: 30%;
  /* text-align: center; */
  /* border: 1px dashed #e9e9e9; */
}
.create-icons-space {
  margin-right: 10;
  margin-bottom: 20;
}
.form-address-content {
  /* margin-top: 24px; */
  margin-right: 10%;
  margin-left: 15%;
  text-align: center;
  /* border: 1px dashed #e9e9e9; */
}
.ant-div-carapace-small {
  margin-top: 24px;
  margin-right: 20%;
  margin-left: 20%;
  text-align: center;
  /* border: 1px dashed #e9e9e9; */
}
.ant-button-carapace {
  width: 180px;
  text-align: center;
  margin-top: 30px;
}
.ant-div-carapace-right {
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
  padding: 30px;
  /* margin-left: 10%; */
}
.top-buttons {
  text-align: left;
  margin-bottom: 40px;
}
.received-nfts {
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 2%;
  margin-left: 2%;
}
.div-header {
  margin-right: 2%;
  margin-left: 2%;
}
.manage-vault {
  border: solid;
}
.header-menu {
  position: "fixed";
  top: 0;
  width: "100%";
  z-index: 1;
  transition: top 0.6s;
}
.header-menu-hidden {
  top: -50px;
}
.body-content {
  margin-top: 90px;
}